
import React, { useState, createRef } from 'react';
import './Form.scss';

// Contact form manages dispatching requests to internal API
const FormComponent = ({
    id = 'form',
    className = 'form',
    notation = undefined,
    action = '',
    method = 'POST',
    sentMessage = 'Your form has been sent.',
    children = null,
    title = ''
}) => {
    const formRef = createRef();
    const [ state, setState ] = useState({
        sent: false,
        loading: false
    });
    // Handles dispatching the event to the server
    async function onSubmit(event) {
        event.preventDefault();
        let error = false;
        let body = Object.values(formRef.current.elements).reduce(
            (memo, el, index) => {
                if (
                    el.tagName !== 'INPUT' &&
                    el.tagName !== 'TEXTAREA' &&
                    (! el.hasAttribute || el.hasAttribute('required') === false)
                ) {
                    return memo;
                } else if (el.value === '' && el.hasAttribute('required') === true) {
                    el.classList.add('g-form-error');
                    error = true;
                } else {
                    el.classList.remove('g-form-error');
                    memo[el.getAttribute("name")] = el.value;
                }
                return memo;
            },
            {}
        )
        // Exit before sending form on required field error
        if (error === true) {
            return false;
        }
        try {
            console.log(body);
            await fetch(action, { method: method, body: JSON.stringify(body) });
            setState({ loading: true, sent: true });
        } catch(err) {
            setState({ loading: false, sent: false });
            alert("There was an error sending your form, please try again.");
        }
        return true;
    }
    // Displays the regular form components
    function content() {
        return <>
            <div className="c-form-title">{ title }</div>
            { children }
            {/* <div class="cf-turnstile" data-sitekey="0x4AAAAAAADBlupbGTKhVDKP" data-callback="javascriptCallback" data-theme="light"></div> */}
            <button
                type="submit"
                aria-label="Submit form"
                disabled={ state.loading === true ? true : false }
                className="g-button no-arrow">
                <img src="/img/icons/nav-arrow.svg" alt="Send form" />&nbsp;
                { state.loading === true ? 'Sending...' : 'Send' }
            </button>
            {
                notation !== undefined ? (
                    <div className="c-form-note">
                        <sub>*</sub> { notation } 
                    </div>
                ) : null
            }
        </>
    }
    // Renders out the success message upon dispatch
    function sent() {
        return (
            <div className="c-form-sent">
                <h2>Thanks!</h2>
                <span>{ sentMessage }</span>
            </div>
        );
    }
    return (
        <form
            id={ id }
            ref = { formRef }
            className={ `${ className } g-box-outline c-form` }
            noValidate
            onSubmit={ onSubmit }>
                { state.sent === true ? sent() : content() }
        </form>
    )
}

export default FormComponent;
