
import React from 'react';
import Global from '../layout/global';
import Form from '../components/Form';
import site from '../../config/site';
import { graphql } from 'gatsby';
import './contact.scss';

/**
 * Contact page that contains a dynamic form and social media.
 */
const Contact = ({ data }) => (
    <Global
        title="Contact us"
        background="/img/background/homepage2.jpg"
        description={ data.markdownRemark.frontmatter.description }
        keywords={ data.markdownRemark.frontmatter.keywords }
        page="contact">
        <div className="contact-top">
            <h1 className="glitch" data-text="Contact">Contact</h1>
            <div className="contact-top-emails">
                <div className="contact-top-email">
                    <div className="contact-top-email-title">General Inquiries</div>
                    <a href="mailto:info@copperhead.co">{ site.general_email }</a>
                </div>
                <div className="contact-top-email">
                    <div className="contact-top-email-title">Media &amp; Press</div>
                    <a href="mailto:media@copperhead.co">{ site.media_email }</a>
                </div>
                <div className="contact-top-email">
                    <div className="contact-top-email-title">Purchase devices online</div>
                    <a href="https://store.cryptocloud.mobi/">Visit Global Master Distributor</a>
                </div>
            </div>
        </div>
        <div className="contact-bottom">
            <div className="contact-bottom-content">
                <div className="contact-bottom-content-form">
                    <Form
                        id="getstarted"
                        // captcha={ true }
                        action="/api/contact"
                        method="POST"
                        title="Contact us to learn more or get started"
                        sentMessage="Your message has been sent. A representive will be in touch with you shortly.">
                        <fieldset>
                            <label htmlFor="name">Contact information</label>
                            <input type="text" aria-label="Name" name="name" placeholder="Name *" required />
                            <input type="text" aria-label="Country" name="country" placeholder="Country *" required/>
                            <input type="email" aria-label="Email" name="email" placeholder="Email address *" required/>
                        </fieldset>
                        <fieldset>
                        <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" aria-label="How can we help?" placeholder="How can we help?"></textarea>
                        </fieldset>
                    </Form>
                </div>
                <div className="contact-bottom-content-social">
                    <a rel="noopener noreferrer" href={ `https://www.${ site.social.facebook }` } target="_blank">
                        <div><img src="/img/icons/social/facebook.svg" alt="Facebook" /></div>
                        <span>{ site.social.facebook } </span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.twitter.com/${ site.social.twitter }` } target="_blank">
                        <div><img src="/img/icons/social/twitter.svg" alt="Twitter" /></div>
                        <span>@{ site.social.twitter }</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.linkedin.com/company/${ site.social.linkedin }` } target="_blank">
                        <div><img src="/img/icons/social/linkedin.svg" alt="LinkedIn" /></div>
                        <span>LinkedIn – Copperhead Security</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.reddit.com/${ site.social.reddit }` } target="_blank">
                        <div><img src="/img/icons/social/reddit.svg" alt="Reddit" /></div>
                        <span>{ site.social.reddit }</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.youtube.com/c/CopperheadOS` } target="_blank">
                        <div><img src="/img/icons/social/youtube.svg" alt="YouTube" /></div>
                        <span>YouTube</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://t.me/copperheadnews` } target="_blank">
                        <div><img src="/img/icons/social/telegram.svg" alt="Telegram" /></div>
                        <span>Telegram</span>
                    </a>
                </div>
            </div>
        </div>
    </Global>
);

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { path: { eq: "/contact" } }) {
            html
            frontmatter {
                title
                date
                description
                keywords
            }
        }
    }
`;

export default Contact;
